import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import "./SmsForm.scss";
import "./Sms.scss"
import { Cipher } from 'crypto';

class SmsForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            patientName: '',
            phoneNumber: '',
            startDate: new Date(),
            time: '',
            treatmentName: ''
        };


     
      }
      /**
       * 
          props.auth.currentSession()
        .then( token => token.getIdToken())
        .then( idToken => {
          const res = fetch("https://z3gr4qg7n2.execute-api.eu-central-1.amazonaws.com/prod/sms", {
            headers: {
              Authorization: `Bearer ${idToken.getJwtToken()}`
            },
            method: "GET"
          }
          ).then(res => res.json())
          .then(result => {
            console.log(result.message)
          })
        })
       */
    
//      const [message, setData] = useState();

  //    useEffect(() =>  {
   //     loadData();
        
     // }, []);
    
     
    
    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({[nam]: val});
    }

    handleChange = date => {
        this.setState({
          startDate: date
        });
      };
    
    mySubmitHandler = (event) => {

        event.preventDefault();
        console.log(this.state)

        this.props.auth.currentSession()
        .then( token => token.getIdToken())
        .then( idToken => {

            
            const data = { 
                patient_first_name:this.state.patientName, 
                phone_number:this.state.phoneNumber,
                date: moment(this.state.startDate).format(moment.HTML5_FMT.DATETIME_LOCAL).replace('T', ' '),
                treatment:this.state.treatmentName
            }
        
            console.log('TOKEN!', idToken.getJwtToken())
            
            // "https://ldviz52le1.execute-api.eu-central-1.amazonaws.com/prod/sms"
            fetch("https://bt3s155zff.execute-api.eu-central-1.amazonaws.com/prod/sms", 
                {   method: 'POST', // or ‘PUT’
                    body: JSON.stringify(data), // data can be `string` or {object}!
                    headers:{ 
                        'Content-Type' : 'application/json', 
                        'Authorization': `Bearer ${idToken.getJwtToken()}`
                    } 
                })
            .then(res => res.json())
            .then(response => console.log('Success:', response))
            .catch(error => {
                console.error('Error:', error)
              alert("משהו השתבש")
            })

  
          })
          event.target.reset()  
        }

/*
        const url ="https://oe39xog2w9.execute-api.eu-central-1.amazonaws.com/dev/notify/scheduled-appointment"
        const data = { 
            patient_first_name:this.state.patientName, 
            phone_number:this.state.phoneNumber,
            date: moment(this.state.startDate).format(moment.HTML5_FMT.DATETIME_LOCAL).replace('T', ' '),
            treatment:this.state.treatmentName
        }
        console.log(data)

        fetch(url, 
            { method: 'POST', // or ‘PUT’
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers:{ 'Content-Type' : 'application/json', 'crossDomain' : true } 
        })
        .then(res => res.json())
        .catch(error => {
            console.error('Error:', error)
            alert("משהו השתבש")
        })
        .then(response => console.log('Success:', response))
        .then(event.target.reset());
      */
        // .then(alert("ההודעה נשלחה בהצלחה")); 
    
    render() {
        return (
        <div className="sms-form-container">
        <form className="sms-form" onSubmit={this.mySubmitHandler}>
            <h1 className="sms-form__header">שליחת סמס</h1>
            <p className="sms-form__label">שם מטופל</p>
            <input
            type="text"
            name="patientName"
            onChange={this.myChangeHandler}
            />
            <p className="sms-form__label">מספר טלפון</p>
            <input className="sms-form__input"
            type="text"
            name="phoneNumber"
            onChange={this.myChangeHandler}
            />
             <p className="sms-form__label">תאריך</p>
            <DatePicker
            selected={this.state.startDate}
            onChange={this.handleChange}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="time"
            dateFormat="yyyy-MM-dd HH:mm"
            />
             <p className="sms-form__label">שם טיפול</p>
            <input
            type="text"
            name="treatmentName"
            onChange={this.myChangeHandler}
            />
            <div class="sms-from__submit__container">
                <input className="sms-from__submit"
                type='submit'
                value="שלח"
            />
            </div>
        </form>
        </div>
        );
    }
}

export default SmsForm;