import React, { useState, useEffect } from 'react';
import './App.css';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import SmsForm from './components/SmsForm';
Amplify.configure(awsconfig);


const App = () => {

  return (
  <div>
    <div className="App__sign_out"><AmplifySignOut /></div>
    ODCPRO!
    <SmsForm auth={Auth}/>
  </div>
)};

export default withAuthenticator(App);